// ** WYWIWYG/Redactor Styling ** //

.wysiwyg,
.wysiwyg-card {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      @apply mt-0;
    }

    &:last-child {
      @apply mb-0;
    }
  }

  p {
    @apply mb-2 font-normal;
  }

  p:not(.eyebrow)+h1 {
    @apply mt-12;

    @media (max-width: 640px) {
      @apply mt-8;
    }
  }

  p:not(.eyebrow)+h2 {
    @apply mt-8;

    @media (max-width: 640px) {
      @apply mt-6;
    }
  }

  p:not(.eyebrow)+h3,
  p:not(.eyebrow)+h4 {
    @apply mt-6;
  }

  p,
  span {
    @apply text-baselg leading-loosest;

    &.xxlarge {
      @apply text-xl mb-4 font-extrabold leading-tight;

      p,
      span {
        @apply text-xl;
      }

      @media (max-width: 640px) {
        @apply text-[28px] mb-3;

        p,
        span {
          @apply text-[28px];
        }
      }
    }

    &.xlarge {
      @apply text-lg mb-3 font-extrabold leading-tight;

      p,
      span {
        @apply text-lg;
      }
    }

    &.extra-large {
      @apply text-lg;

      p,
      span {
        @apply text-lg;
      }
    }

    &.large {
      @apply text-md;

      a {
        @apply text-md;
      }
    }

    &.small {
      @apply text-base;

      a {
        @apply text-base;
      }
    }

    &.xsmall {
      @apply text-sm;

      a {
        @apply text-sm;
      }
    }

    &:last-child {
      @apply mb-0;
    }
  }

  a:not(.primary-button, .secondary-button, .tertiary-button, .with-arrow, .card-button),
  .highlighted-text-modal-1,
  .highlighted-text-modal-2 {
    @apply text-inherit underline text-vis-link leading-loosest;

    &.large {
      @apply text-md;
    }

    &.small {
      @apply text-base;
    }

    &.xsmall {
      @apply text-sm;
    }
  }

  h1 span,
  h2 span,
  h3 span {
    font-size: inherit;
    line-height: inherit;
  }

  strong {
    @apply font-extrabold;

    p,
    span {
      @apply font-extrabold;
    }
  }

  ul,
  ol {
    @apply list-disc mb-v-spacing;
    margin-block-start: 0px;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 32px;

    li {
      @apply mb-2;

      &:last-child {
        @apply mb-0;
      }

      a {
        font-size: inherit;
      }
    }
  }

  ol {
    @apply list-decimal;
  }

  figure {
    @apply mb-8;
    max-width: 100% !important;

    img {
      display: inline-block;

      &.hidden {
        display: none;
      }
    }

    figcaption {
      text-align: center;
    }

    &.--left {
      @apply justify-start;
    }

    &.--center {
      @apply justify-center;
    }

    &.--right {
      @apply justify-end;
    }

    &:last-child img {
      @apply mb-0;
    }
  }

  &.headingConfig {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }

  table {
    @apply w-full;

    th,
    td {
      @apply p-1.5;
      text-align: initial;
    }

    tr {
      @apply border-b border-solid;
      border-color: #f2f2f3;
    }
  }

  .eyebrow {
    @apply font-extrabold text-sm leading-looser mb-2 tracking-wider uppercase mb-2 text-vis-subtle;

    a {
      @apply text-sm;
    }
  }

  .bottom-content {
    @apply mt-0 pt-4;
  }

  .bottom-content.bottom-content-title {
    @apply mb-[-1rem];
  }

  .disclaimer {
    @apply text-xs;

    a {
      @apply text-xs;
    }
  }

  .disclaimer-sm {
    @apply text-xxs leading-3;
  }
}

.wysiwyg-card {

  ul,
  ol {
    padding-inline-start: 14px;
  }
}

.wysiwyg-slide-card {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-bottom: 0.5rem;
    }

    &:last-child {
      @apply mb-0;
    }
  }

  .eyebrow {
    @apply font-extrabold text-sm leading-5 tracking-wider uppercase text-vis-subtle mb-2;
  }

  .disclaimer {
    @apply text-xs leading-3;
  }

  .disclaimer-sm {
    @apply text-xxs leading-3;
  }
}

@mixin lightBackgrounds {

  .wysiwyg,
  .wysiwyg-card {

    .eyebrow {
      @apply text-vis-subtle;
    }

    a:not(.primary-button, .secondary-button, .tertiary-button, .with-arrow, .card-button),
    .highlighted-text-modal-1,
    .highlighted-text-modal-2 {
      @apply text-inherit underline text-vis-link leading-loosest;

    }
  }

}

// ** CTA styles for light background elements ** //
@mixin darkBackgrounds {

  .wysiwyg,
  .wysiwyg-card {

    .eyebrow {
      @apply text-vis-reverse;
    }

    a:not(.primary-button, .secondary-button, .tertiary-button, .with-arrow, .card-button),
    .highlighted-text-modal-1,
    .highlighted-text-modal-2 {
      @apply text-inherit underline text-vis-reverse leading-loosest;

    }
  }
}

@import './_apply-background';