@import './backgrounds.scss';

$arrow-svg: 'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>';
$chevron-right-svg: 'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 25"><path d="M12.4751 12.7222L5.12511 5.37223C4.87511 5.12223 4.75428 4.8264 4.76261 4.48473C4.77094 4.14306 4.90011 3.84723 5.15011 3.59723C5.40011 3.34723 5.69594 3.22223 6.03761 3.22223C6.37928 3.22223 6.67511 3.34723 6.92511 3.59723L14.6001 11.2972C14.8001 11.4972 14.9501 11.7222 15.0501 11.9722C15.1501 12.2222 15.2001 12.4722 15.2001 12.7222C15.2001 12.9722 15.1501 13.2222 15.0501 13.4722C14.9501 13.7222 14.8001 13.9472 14.6001 14.1472L6.90011 21.8472C6.65011 22.0972 6.35844 22.2181 6.02511 22.2097C5.69178 22.2014 5.40011 22.0722 5.15011 21.8222C4.90011 21.5722 4.77511 21.2764 4.77511 20.9347C4.77511 20.5931 4.90011 20.2972 5.15011 20.0472L12.4751 12.7222Z"/></svg>';

// ** Global CTA styles for primary, secondary, and tertiary buttons not associated with a specific background color ** //

.primary-button,
.secondary-button,
.dropdown-button,
#mobile_select {
  @apply gap-2 w-full rounded-full font-bold text-center text-baselg cursor-pointer items-center justify-center no-underline;
}

// Primary and dropdown CTA
.primary-button,
.dropdown-button,
#mobile_select {
  @apply text-vis-reverse bg-visBtn-primary p-visBtn;

  &:hover {
    @apply bg-visBtn-primaryHover;
  }

  &:active {
    @apply bg-visBtn-primaryPressed;
  }
}

.primary-button,
.secondary-button,
.dropdown-button {
  @apply inline-flex;
}

.dropdown-button {
  @apply justify-between;
}

// Secondary CTA
.secondary-button {
  @apply bg-vis-reverse text-visBtn-secondary border-visBtn-secondary border-[1.5px] p-visBorderBtn;

  &:hover {
    @apply bg-visBtn-secondaryHover text-vis-reverse;
    .video-icon-fill {
      @apply fill-vis-reverse;
    }
  }

  &:active {
    @apply bg-visBtn-secondaryPressed text-vis-reverse;
    .video-icon-fill {
      @apply fill-vis-reverse;
    }
  }

  .video-icon-fill {
    @apply fill-visBtn-secondary;
  }
}

// Tertiary CTA
.tertiary-button {
  @apply text-visBtn-tertiary text-baselg p-0 gap-0 inline-flex font-extrabold items-center cursor-pointer leading-6 underline underline-offset-[6px] decoration-[1.5px];

  &:has(.tertiary-no-underline) {
    text-decoration: none;
  }

  .tertiary-no-underline {
    text-decoration: none;
  }

  &:hover {
    @apply text-visBtn-tertiaryHover;
  }

  &:active {
    @apply text-visBtn-tertiaryPressed;
  }
}

// Tertiary CTA with arrow
.tertiary-button.with-arrow {
  @apply no-underline text-visBtn-tertiary;

  &::after {
    content: '';
    mask: url($arrow-svg) no-repeat center;
    -webkit-mask: url($arrow-svg) no-repeat center;
    mask-size: contain;
    -webkit-mask-size: contain;
    width: 1em;
    height: 1em;
    @apply ml-1 bg-visBtn-tertiary inline-block;
  }

  &:hover {
    @apply text-visBtn-tertiaryHover;

    &::after {
      @apply bg-visBtn-tertiaryHover;
    }
  }
}

.card-button {
  @apply flex bg-vis-white border-vis-grey-2 border-[1px] text-vis-regular font-extrabold py-6 px-4 rounded-card-button items-center cursor-pointer;
  box-shadow: 0px 1px 8px 0px rgba(34, 34, 34, 0.10);
  max-width: 440px;

  span {
    @apply leading-normal mr-3;
  }

  &::after {
    content: '';
    mask: url($chevron-right-svg) no-repeat center;
    -webkit-mask: url($chevron-right-svg) no-repeat center;
    mask-size: contain;
    -webkit-mask-size: contain;
    @apply ml-auto bg-visBtn-primary inline-block w-5 h-6;
  }

  &:hover {
    @apply text-visBtn-primaryHover border-visBtn-primaryHover;
  }

  &:active {
    @apply bg-visBtn-primary border-visBtn-primary text-vis-reverse;
    &::after {
      @apply bg-vis-reverse;
    }
  }

  &.disabled {
    @apply bg-visBtn-disabled text-vis-subtle border-visBtn-disabled pointer-events-none;
    &::after {
      @apply bg-vis-subtle;;
    }
  }
}

// Dropdown styling
#mobile_select {
  @apply bg-no-repeat;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  background-image: url('https://xgrit-ecom.imgix.net/ace/ace-dropdown-caret.png?v=1702609166');
}

.dropdown-item {
  @apply text-ace-grey-900;

  &:hover {
    @apply font-semibold bg-ace-teal-200;
  }
}

.dropdown-items {
  @apply flex-row float-right;
}

.dropdown-icon {
  @apply ml-3;
}

.dropdown-icon-rotated {
  @apply rotate-[180deg]; // 3.142 radians ≈ 180 degrees
}

.dropdown-item-line {
  @apply w-[30px] h-0 border border-white rotate-90 mr-3 mb-[1px];
}

.dropdown-label {
  @apply mb-2;
}

.dropdown-text {
  @apply float-left;
}

// ** CTA styles for light background elements ** //
@mixin lightBackgrounds {
  .secondary-button {
    @apply bg-vis-reverse text-visBtn-secondary border-visBtn-secondary no-underline border-[1.5px] p-visBorderBtn;

    &:hover {
      @apply bg-visBtn-secondaryHover text-vis-reverse;
    }

    &:active {
      @apply bg-visBtn-secondaryPressed text-vis-reverse;
    }
  }

  .tertiary-button {
    @apply text-visBtn-tertiary;

    &:hover {
      @apply text-visBtn-tertiaryHover;
    }

    &:active {
      @apply text-visBtn-tertiaryPressed;
    }
  }

  .tertiary-button.with-arrow {
    @apply no-underline;
  }
}

// ** CTA styles for light background elements ** //
@mixin darkBackgrounds {
  .secondary-button {
    @apply border-0 p-visBtn;
  }

  .tertiary-button {
    @apply text-white;
  }

  .tertiary-button.with-arrow {
    @apply text-white no-underline;
    &::after {
      @apply bg-vis-reverse;
    }
  }
}

@import './_apply-background';
