@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/controller';
@import './styles/typography';
@import './styles/redactor';
@import './styles/buttons';

html,
body,
.font-body {
  scroll-behavior: smooth;
}

.body-margin {
  @apply mb-32 sm:mb-20;
}

//fa-icon overides for ssr resizing issue https://github.com/FortAwesome/angular-fontawesome/issues/48
.svg-inline--fa {
  vertical-align: -0.125em;
}

fa-icon svg {
  display: inline-block;
  font-size: inherit;
  height: 1em;
}

fa-icon .fa-2x {
  font-size: 2em;
}

.sticky-header {
  @apply fixed top-0 bg-white block z-20 w-full;
  & + div.mx-auto {
    @apply mt-40;
  }
}
.triangle-pointer-up {
  width: 0px;
  height: 0px;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-bottom: 1em solid white;
}

input::placeholder {
  @apply mb-2 font-extrabold leading-looser text-sm;
}

.ace-home-hero-gradient {
  background: linear-gradient(90deg, #12a1cd, #12cdca);
}

.cta-lg {
  padding: 1rem 2rem;
}

.cta-md {
  width: auto;
  min-width: 139px;
  max-width: 280px;
  min-height: 48px;
}

.container {
  @apply py-15;
  @apply mx-auto;
  @apply px-v-spacing;
  max-width: 100%;

  @media (max-width: 767px) {
    @apply py-12;
  }
  @media (min-width: 640px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  @media (min-width: 1280px) {
    @apply max-w-container;
  }
}

.slider-container .swiper {
  @apply grid grid-cols-2;
  max-width: 100%;
  overflow: visible !important;
}
.swiper-wrapper {
  grid-column: 1 / span 2;
  overflow: visible;
}
.slider-inner-wrapper {
  @apply mx-auto;
  @apply px-v-spacing;
  @media (max-width: 767px) {
    @apply pt-12 pb-10;
  }
  @media (min-width: 640px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  @media (min-width: 1280px) {
    max-width: 1140px;
  }
}

.slider-section {
  overflow: hidden !important;
}

.slider-container {
  padding-top: 24px;
}

.aspect-ratio-16-9 {
  position: relative;
  padding-bottom: calc(9 / 16 * 100%);
  overflow: hidden;

  // For old browsers that don't support min(), YouTube videos will span the full width.
  @supports not (padding-bottom: calc(9 / 16 * 100%)) {
    padding-bottom: 56.25%; // 9 / 6 * 100
    max-width: none;
  }

  > figure {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: auto;
    bottom: 0;
    left: auto;

    > iframe {
      height: 100%;
    }
  }

  > iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: auto;
    bottom: 0;
    left: auto;
  }
}

.wistia-video {
  margin: 0 auto;

  @screen lg {
    &.--75 {
      width: 75%;
    }
    &.--50 {
      width: 50%;
    }
    &.--25 {
      width: 25%;
    }
    &.--100 {
      width: 100%;
    }
  }
}

.rec-dec-video {
  display: block;

  .poster {
    @apply absolute h-full w-1/2 cursor-pointer;

    &__img {
      @apply pointer-events-none w-full object-cover absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
  }

  &.--left {
    .poster,
    iframe,
    video {
      left: 0;
    }
  }

  &.--center {
    .poster,
    iframe,
    video {
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }

  &.--right {
    .poster,
    iframe,
    video {
      right: 0;
    }
  }

  &.--100 {
    .poster,
    iframe,
    video {
      display: inline-block;
      max-width: none;
      width: 100%;
      height: 100%;
    }
  }

  // Mobile & tablet - show the video/iframe/poster at full-width
  &.--75,
  &.--50,
  &.--25 {
    padding-bottom: calc(9 / 16 * 100%);

    .poster,
    iframe,
    video {
      width: 100%;
    }
  }

  // Desktop and larger - show the video at the specified width percentage
  @screen lg {
    &.--75 {
      padding-bottom: calc(9 / 16 * 75%);

      .poster,
      iframe,
      video {
        width: 75%;
      }
    }

    &.--50 {
      padding-bottom: calc(9 / 16 * 50%);

      .poster,
      iframe,
      video {
        width: 50%;
      }
    }

    &.--25 {
      padding-bottom: calc(9 / 16 * 25%);

      .poster,
      iframe,
      video {
        width: 25%;
      }
    }
  }
}
.vis-underline {
  white-space: nowrap;
  background-repeat: no-repeat;
  background-position-y: bottom;
  padding-bottom: 5px;
  background-image: url('https://xgrit-ecom.imgix.net/v3_images/Drivers-Ed/dec-underline.png?v=1738176272');
}
.dec-underline {
  white-space: nowrap;
  background-repeat: no-repeat;
  background-position-y: bottom;
  padding-bottom: 5px;
  background-image: url('https://xgrit-ecom.imgix.net/v3_images/Drivers-Ed/dec-underline.png?v=1738176272');
}
.aa-underline {
  white-space: nowrap;
  background-repeat: no-repeat;
  background-position-y: bottom;
  padding-bottom: 5px;
}
.ace-underline {
  white-space: nowrap;
  background-repeat: no-repeat;
  background-position-y: bottom;
  padding-bottom: 5px;
}

