/*
  This file handles applying background-specific styles dynamically. 
  It loops through predefined light and dark background classes 
  (which are set in _backgrounds.scss) and applies the right styles 
  using mixins. This keeps things DRY so we don’t have to manually 
  define styles for every single background class.

  The nested loops at the bottom make sure that if an element inside 
  a light background has a dark background (or vice versa), it gets the 
  correct styles. No weird overrides!
*/

@import './_backgrounds'; // Ensure variables are available

@each $light-bg in $light-backgrounds {
  .#{$light-bg} {
    @include lightBackgrounds;
  }
}

@each $dark-bg in $dark-backgrounds {
  .#{$dark-bg} {
    @include darkBackgrounds;
  }
}

// ** Handle nested background overrides ** //
@each $light-bg in $light-backgrounds {
  @each $dark-bg in $dark-backgrounds {
    .#{$light-bg} .#{$dark-bg} {
      @include darkBackgrounds;
    }
  }
}

@each $dark-bg in $dark-backgrounds {
  @each $light-bg in $light-backgrounds {
    .#{$dark-bg} .#{$light-bg} {
      @include lightBackgrounds;
    }
  }
}
