@font-face {
  font-family: 'Nunito Sans';
  src: url('/ace-assets/fonts/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype');
  font-weight: 100 1000;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/ace-assets/fonts/NunitoSans-Italic-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype');
  font-weight: 100 1000;
  font-style: italic;
}
