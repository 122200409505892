@import './backgrounds';

// ** TYPOGRAPHY STYLES ** //

.eyebrow {
  @apply font-extrabold text-sm uppercase leading-5 tracking-wider mb-2;
}

.highlighted-text-modal-1,
.highlighted-text-modal-2 {
  @apply text-baselg underline text-vis-link leading-loosest;

  &:hover {
    @apply text-ace-teal-950;
  }

  &.large {
    @apply text-md;
  }

  &.small {
    @apply text-base;
  }

  &.xsmall {
    @apply text-sm;
  }
}

// Typography scales for headings
h1 {
  @apply text-5xl font-extrabold leading-looser mb-6;
  @media (max-width: 640px) {
    @apply text-2xl mb-4;
  }
  span {
    @media (max-width: 640px) {
      @apply text-2xl mb-4;
    }
  }
}

h2 {
  @apply text-xl font-extrabold leading-looser mb-4;
  @media (max-width: 640px) {
    @apply text-2lg mb-3;
  }
}

h3 {
  @apply text-lg font-extrabold leading-looser mb-3;
  @media (max-width: 640px) {
    @apply text-lg;
  }
}

h4 {
  @apply text-md font-extrabold leading-looser mb-3;
}

h5,
h6 {
  @apply text-xs font-bold;
  @apply leading-5;
}

// ** Mixin Definitions **
// Mixin for light backgrounds
@mixin lightBackgrounds {
  .eyebrow {
    @apply text-ace-grey-600;
  }

  .highlighted-text-modal-1,
  .highlighted-text-modal-2 {
    @apply text-vis-link underline;
  }
}

// Mixin for dark backgrounds
@mixin darkBackgrounds {
  .eyebrow {
    @apply text-vis-reverse;
  }

  .highlighted-text-modal-1,
  .highlighted-text-modal-2 {
    @apply text-vis-reverse underline;
  }
}

@import './_apply-background';

